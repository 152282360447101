import React from "react";
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

export const ProjectWorks = () => {
    const [emblaRef] = useEmblaCarousel({
        align: 'center',
        skipSnaps: true,
        containScroll: 'trimSnaps',
        loop: true,
        draggable: true,
    }, [Autoplay({ delay: 2000 })]);
    return (
        <section data-bs-version="5.1" className="slider1 mbr-embla cid-talUAKDIh1" id="slider01-1o">
            <div className="position-relative text-center">
                <div className="mbr-section-head container">
                    <h4 className="mbr-section-title mbr-fonts-style align-left mb-0 display-2">
                        <strong>PROJECT WORKS</strong>
                    </h4>
                </div>
                <div className="embla mt-4" ref={emblaRef}>
                    <div className="embla__container">
                        <div className="embla__slide slider-image item active"
                             style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                            <div className="slide-content">
                                <div className="item-wrapper">
                                    <div className="item-img">
                                        <img src="/images/image1-588x504.jpg" data-slide-to="1"
											 data-bs-slide-to="1" loading="lazy" className="lazyload"
											 data-src="/images/image1-588x504.jpg" alt={""}/>
                                    </div>
                                </div>
                                <div className="item-content">
                                    <div className="img-link">
                                        <h2 className="mbr-fonts-style m-0 display-7">COMMERCIAL CONSTRUCTION</h2>
                                        <span className="mbr-iconfont link-icon mobi-mbri-right mobi-mbri"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="embla__slide slider-image item"
                             style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                            <div className="slide-content">
                                <div className="item-wrapper">
                                    <div className="item-img">
                                        <img src="/images/image2-548x471.jpg" data-slide-to="2"
											 data-bs-slide-to="2" loading="lazy" className="lazyload"
											 data-src="/images/image2-548x471.jpg" alt={""}/>
                                    </div>
                                </div>
                                <div className="item-content">
                                    <div className="img-link">
                                        <h2 className="mbr-fonts-style m-0 display-7">HOME DEVELOPMENT</h2>
                                        <span className="mbr-iconfont link-icon mobi-mbri-right mobi-mbri"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="embla__slide slider-image item"
                             style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                            <div className="slide-content">
                                <div className="item-wrapper">
                                    <div className="item-img">
                                        <img src="/images/image3-586x504.jpg" data-slide-to="2"
											 data-bs-slide-to="2" loading="lazy" className="lazyload"
											 data-src="/images/image3-586x504.jpg" alt={""}/>
                                    </div>
                                </div>
                                <div className="item-content">
                                    <div className="img-link">
                                        <h2 className="mbr-fonts-style m-0 display-7">REAL ESTATE EXPANSION</h2>
                                        <span className="mbr-iconfont link-icon mobi-mbri-right mobi-mbri"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="embla__slide slider-image item"
                             style={{ marginLeft: "2rem", marginRight: "2rem" }}>
                            <div className="slide-content">
                                <div className="item-wrapper">
                                    <div className="item-img">
                                        <img src="/images/image4-588x504.jpg" data-slide-to="3"
											 data-bs-slide-to="3" loading="lazy" className="lazyload"
											 data-src="/images/image4-588x504.jpg" alt={""}/>
                                    </div>
                                </div>
                                <div className="item-content">
                                    <div className="img-link">
                                        <h2 className="mbr-fonts-style m-0 display-7">COMMUNITY SERVICES</h2>
                                        <span className="mbr-iconfont link-icon mobi-mbri-right mobi-mbri"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="embla__button embla__button--prev">
                        <span className="mobi-mbri mobi-mbri-arrow-prev mbr-iconfont" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden visually-hidden">Previous</span>
                    </button>
                    <button className="embla__button embla__button--next">
                        <span className="mobi-mbri mobi-mbri-arrow-next mbr-iconfont" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </section>
    );
}