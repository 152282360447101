import React, {useState} from 'react';
import {env} from "../environment";
import {Link} from "react-router-dom";

export const Header = () => {
    const [navHeight, setNavHeight] = useState<0 | '100%'>(0);

    const closeNav = () => {
        setNavHeight(0);
    }

    const openNav = () => {
        setNavHeight('100%');
    }

    return (
        <section data-bs-version="5.1" className="menu1 cid-tc91E4QaPr" id="menu1-46">
            <nav className="navbar navbar-dropdown navbar-light collapsed">
                <div className="container-fluid">
                    <div className="navbar-brand">
                        <span className="navbar-logo">
                            <a href="/">
                                <img src="/images/logo.png" alt="" loading="lazy"
                                     className="lazyload" data-src="/images/logo.png"/>
                            </a>
                        </span>
                        {window.innerWidth > 425 && (
                            <span className="navbar-caption-wrap d-none d-lg-block">
                                <a className="navbar-caption text-black text-primary display-5"
                                      href="/">{env.siteName}</a>
                            </span>
                        )}
                    </div>
                    <button className="navbar-toggler" onClick={openNav}>
                        <div className="hamburger">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    <div style={{ height: navHeight }} className="overlay">
                        <Link to="#" className="closebtn" onClick={closeNav}>&times;</Link>
                        <ul className="navbar-nav nav-dropdown navbar-light nav-right overlay-content"
                            data-app-modern-menu="true">
                            <li className="nav-item">
                                <Link className="nav-link link text-black text-primary"
                                      style={{ fontSize: "2.5rem", fontFamily: "Georgia" }} to="/about-us" aria-expanded="false" onClick={closeNav}>
                                    About Us<br/>
                                    <div className="line-animation"></div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link link text-black text-primary" to="/services"
                                      style={{ fontSize: "2.5rem", fontFamily: "Georgia" }} aria-expanded="false" onClick={closeNav}>
                                    Services<br/>
                                    <div className="line-animation"></div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link link text-black text-primary"
                                      style={{ fontSize: "2.5rem", fontFamily: "Georgia" }} to="/projects" onClick={closeNav}>
                                    Projects<br/>
                                    <div className="line-animation"></div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link link text-black text-primary"
                                      style={{ fontSize: "2.5rem", fontFamily: "Georgia" }} to="/careers" onClick={closeNav}>
                                    Careers<br/>
                                    <div className="line-animation"></div>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link link text-black text-primary"
								   style={{ fontSize: "2.5rem", fontFamily: "Georgia" }} href="/contact-us" onClick={closeNav}>
                                    Contact Us<br/>
                                    <div className="line-animation"></div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    );
}